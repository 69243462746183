$(document).ready(function () {
	$(window).scroll(function () {
		$(".back-to-top").toggleClass("show", $(window).scrollTop() > 0);
	});

	$("#wowbook").wowBook({
		width: 1240,
		height: 800,
		centeredWhenClosed: true,
		hardcovers: true,
		pageNumbers: false,
		mouseWheel: true,
		controls: {
			zoomIn: "#zoomin",
			zoomOut: "#zoomout",
			next: "#next",
			back: "#back",
			first: "#first",
			last: "#last"
		},
		scaleToFit: ".wowbook-wrapper"
	});
});

const menuMobileMapping = new MappingListener({
	selector: ".menu",
	mobileWrapper: ".mobile-menu",
	mobileMethod: "appendTo",
	desktopWrapper: ".menu-wrapper",
	desktopMethod: "prependTo",
	breakpoint: 1024.98,
}).watch();

var productThumbnailSlider = new Swiper(
	".product-thumbnail-slider .swiper-container",
	{
		spaceBetween: 10,
		slidesPerView: 5,
		observer: true,
		observeParents: true,
		navigation: {
			prevEl: ".product-thumbnail-slider .swiper-prev",
			nextEl: ".product-thumbnail-slider .swiper-next",
		},
		breakpoints: {
			576: {
				slidesPerView: 3,
			},
		},
	}
);

var productImageSlider = new Swiper(
	".product-images-slider .swiper-container",
	{
		spaceBetween: 20,
		observer: true,
		observeParents: true,
		thumbs: {
			swiper: productThumbnailSlider,
		},
	}
);

var whyus = new Swiper(".why-us .swiper-container", {
	slidesPerView: 3,
	navigation: {
		prevEl: ".why-us .swiper-prev",
		nextEl: ".why-us .swiper-next",
	},
	breakpoints: {
		576: {
			slidesPerView: 2,
		}
	},
});

var awards = new Swiper(".awards .swiper-container", {
	spaceBetween: 10,
	slidesPerView: 4,
	navigation: {
		prevEl: ".awards .swiper-prev",
		nextEl: ".awards .swiper-next",
	},
	breakpoints: {
		768.98: {
			slidesPerView: 3,
    },
    576: {
			slidesPerView: 2,
    },
    375.98: {
			slidesPerView: 1,
		}
	},
});

var galleryThumbs = new Swiper(".history .gallery-thumbs", {
	spaceBetween: 20,
	slidesPerView: 4,
  observer: true,
  observeParents: true,
});

var galleryTop = new Swiper(".history  .gallery-top .swiper-container", {
	spaceBetween: 10,
	navigation: {
		nextEl: ".history  .swiper-next",
		prevEl: ".history  .swiper-prev",
  },
  observer: true,
  observeParents: true,
	thumbs: {
		swiper: galleryThumbs,
	},
});
